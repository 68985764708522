import { fbClient, type IUser } from 'featbit-js-client-sdk';
import { defineStore } from 'pinia';

const option = {
	secret: import.meta.env.VITE_FEATBIT_KEY,
	api: import.meta.env.VITE_FEATBIT_URL,
	user: {},
};

export const FeatureFlags: { [key: string]: unknown } = {
	'credit-policy': 'false',
	'proposal-update-action': false,
	'global-search': false,
};

export const featbitService = {
	init: (userData: IUser) => {
		if (process.env.NODE_ENV !== 'development') {
			fbClient.init({ ...option, user: userData });

			const store = useFeatBitStore();

			fbClient.on('ff_update', (changes: any) => (changes.length ? (store.flags = createFlagsProxy()) : null));

			fbClient.waitUntilReady().then((changes: any) => (changes.length ? (store.flags = createFlagsProxy()) : null));
		}
	},
};

export const createFlagsProxy = () => {
	return new Proxy(
		{},
		{
			get(target, prop, receiver) {
				return typeof prop === 'string' && !prop.startsWith('__v_') ? fbClient.variation(prop, FeatureFlags[prop] || '') : '';
			},
		}
	);
};

export const useFeatBitStore = defineStore('featbit', {
	state: () => ({
		flags: createFlagsProxy() as { [key: string]: unknown },
	}),
});
