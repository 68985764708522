import { MAX_FINANCIAL_TERM_COMMERCIAL, MAX_FINANCIAL_TERM_RESIDENTIAL, Messages, MIN_FINANCIAL_TERM, MIN_FINANCIAL_VALUE, PropertyType } from '@/constants';
import type { FormItemRule } from 'naive-ui/es/form';
import { Validations } from './validations';
import type { DataOperationRealEstateFinancingFormData } from '@/components/Forms/DataOperationRealEstateFinancingForm';

export class FormValidations {
	public static IsRequired: FormItemRule = {
		required: true,
		message: Messages.ERROR_IS_REQUIRED,
		validator(rule: FormItemRule, value: string | number | boolean) {
			if (value === null || value === undefined || value === '') return new Error(Messages.ERROR_IS_REQUIRED);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsSelectRequired: FormItemRule = {
		required: true,
		message: Messages.ERROR_IS_REQUIRED,
		validator(rule: FormItemRule, value: string | number | boolean) {
			if (value === null || value === undefined || value === '') return new Error(Messages.ERROR_IS_SELECT_REQUIRED);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsFileRequired: FormItemRule = {
		required: true,
		message: Messages.ERROR_IS_FILE_REQUIRED,
		validator(rule: FormItemRule, value: string | number | boolean) {
			if (value == null || value == undefined || !value) return new Error(Messages.ERROR_IS_FILE_REQUIRED);

			return true;
		},
		trigger: ['blur', 'input'],
	};
	public static IsDateGreaterThanYesterday: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.isDateGreaterThanYesterday(value)) return new Error(Messages.ERROR_DATE_GREATER_THAN_YESTERDAY);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	static MinimalNameLength: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			// Name should contain at least 1 word
			if (value.split(' ').length <= 1) return new Error(Messages.ERROR_NAME_MINIMAL_LENGTH);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsRequiredIf(object: any, inputLabel: string, compareValue: any): FormItemRule {
		let value: any;
		let required = true;

		if (inputLabel in object) {
			value = object[inputLabel];
		}

		required = value == compareValue;

		return {
			required: required,
			message: Messages.ERROR_IS_REQUIRED,
			validator(rule: FormItemRule, value: string | number | boolean) {
				if (!required) return true;

				if (value == null || value == undefined) return new Error(Messages.ERROR_IS_REQUIRED);

				return true;
			},
			trigger: ['blur', 'input'],
		};
	}

	public static IsEmailValid: FormItemRule = {
		required: true,
		validator(rule: FormItemRule, value: string) {
			if (!Validations.validateEmail(value)) return new Error(Messages.ERROR_INVALID_EMAIL);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsCpfOrCnpj: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.validateCnpj(value) && !Validations.validateCpf(value)) return new Error(Messages.ERROR_INVALID_DOCUMENT);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static NoDuplicates(list: string[], message: string): FormItemRule {
		return {
			validator(rule: FormItemRule, value: string) {
				if (list.includes(value)) return new Error(message);

				return true;
			},
			trigger: ['blur', 'input'],
		};
	}

	public static IsCnpjValid: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.validateCnpj(value)) return new Error(Messages.ERROR_INVALID_DOCUMENT);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsGreaterThanZero: FormItemRule = {
		validator(rule: FormItemRule, value: number | string) {
			if (typeof value === 'string') value = Number(value);

			if (value <= 0) return new Error(Messages.ERROR_NUMBER_GREATER_THAN_ZERO);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsDateValid: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.isDateValid(value)) return new Error(Messages.ERROR_INVALID_DATE);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsOver18YearsOld: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.isOver18YearsOld(value)) return new Error(Messages.ERROR_OVER_18_YEARS_OLD);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsOver65YearsOld: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (Validations.isOver65YearsOld(value)) return new Error(Messages.ERROR_OVER_65_YEARS_OLD);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsMinimalOpeningDateValid: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.isMinimalOpeningDate(value)) return new Error(Messages.ERROR_MINIMAL_OPENING_DATE);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsScoreValid: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			let score = parseInt(value);
			if (!(score >= 0 && score <= 1000)) return new Error(Messages.ERROR_SCORE_BETWEEN_0_AND_1000);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsPhoneValid: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (Validations.validatePhoneNumber(value)) return new Error(Messages.ERROR_INVALID_PHONE);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsNameFullFilled: FormItemRule = {
		validator(rule: FormItemRule, value: string) {
			if (!Validations.validateFullname(value)) return new Error(Messages.ERROR_INVALID_NAME);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsArrayFilled: FormItemRule = {
		validator(rule: FormItemRule, value: any[]) {
			if (!Validations.isArrayFullFilled(value)) return new Error(Messages.ERROR_INVALID_ARRAY_IS_EMPTY);

			return true;
		},
		trigger: ['blur', 'input'],
	};

	public static IsTermValid = (data: DataOperationRealEstateFinancingFormData, birhdate: string): FormItemRule => {
		return {
			validator(rule: FormItemRule, value: number) {
				let maxTerm = Validations.validateMaxFinancingTerm(birhdate, value);
				const baseTerm = data.propertyPorpouse == PropertyType.Commercial ? MAX_FINANCIAL_TERM_COMMERCIAL : MAX_FINANCIAL_TERM_RESIDENTIAL;

				if (maxTerm > baseTerm) maxTerm = baseTerm;

				if (maxTerm < 0) return new Error(Messages.ERROR_TERM_BUYER_OVER_LIMIT);
				if (value < MIN_FINANCIAL_TERM) return new Error(Messages.ERROR_TERM_MIN);
				if (value > maxTerm) return new Error(Messages.ERROR_TERM_MAX(maxTerm));

				return true;
			},
			trigger: ['blur', 'input'],
		};
	};

	public static IsOwnResourcesValueValid = (data: DataOperationRealEstateFinancingFormData): FormItemRule => {
		return {
			validator(rule: FormItemRule, value: number) {
				const sumOwnResource = data.ownResourceFgtsValue + data.ownResourceValue;
				if (sumOwnResource >= data.propertyValue) return new Error(Messages.ERROR_FINANCING_VALUE_SHOULD_BE_LOWER);
				if (sumOwnResource == 0) return new Error(Messages.ERROR_FINANCING_OWN_RESOURCES_REQUIRED);
				const minimumFinancingValue = data.propertyValue * 0.2;
				if (sumOwnResource < minimumFinancingValue) return new Error(Messages.ERROR_FINANCING_VALUE_SHOULD_BE_GREATER(minimumFinancingValue));

				return true;
			},
			trigger: ['change', 'blur', 'input'],
		};
	};

	public static IsFinancingValueValid = (data: DataOperationRealEstateFinancingFormData): FormItemRule => {
		return {
			validator(rule: FormItemRule, value: number) {
				// Check if the financing value is less than the minimum
				if (data.financingValue < MIN_FINANCIAL_VALUE) return new Error(Messages.ERROR_FINANCING_VALUE_MIN(MIN_FINANCIAL_VALUE));

				return true;
			},
			trigger: ['change', 'blur', 'input'],
		};
	};
}
