import router from '@/router/router';
import { NA, NButton, NIcon } from 'naive-ui';
import { defineComponent, h, type Component, type PropType } from 'vue';
import { RouterLink, useLink, type RouteRecord } from 'vue-router';
import RenderIcon from '../RenderIcon';

export const AppButton = defineComponent({
	props: {
		// @ts-ignore
		...RouterLink.props,
		to: {
			type: Object,
			default: undefined,
		},
		icon: {
			type: Object as PropType<Component>,
			default: null,
		},
		content: {
			type: String as PropType<string>,
			default: undefined,
		},
	},
	setup(props, attrs) {
		//@ts-ignore
		const { navigate, href } = useLink(props);

		if (props.to)
			return () => (
				//@ts-ignore
				<NButton type="primary" href={href} tag="a" onClick={navigate}>
					{{
						icon: () => (props.icon ? <NIcon component={props.icon} /> : ''),
						default: () => props.content,
					}}
				</NButton>
			);

		return () => (
			<NButton {...{ ...attrs }} type="primary">
				{{
					icon: () => (props.icon ? <NIcon>{h(props.icon)}</NIcon> : ''),
					default: () => props.content,
				}}
			</NButton>
		);
	},
});

export default AppButton;
