export class Colors {
	static primaryColor = '#FFCB74';
	static mainBackgroundColor = '#18181B';
	static softBackground = '#1e1f20';
	static strokeColor = '#242124';
	static success = '#2E8B57';
	static warning = '#FFC300';
	static info = '#1E90FF';
	static danger = '#DC143C';

	static getHash(text: string) {
		let hash = 0;
		if (text.length == 0) {
			return hash;
		}
		for (let i = 0; i < text.length; i++) {
			let charCode = text.charCodeAt(i);
			hash = (hash << 5) - hash + charCode;
			hash = hash & hash;
		}
		return hash;
	}

	static generateColor(username: string, startColor = [201, 174, 93], endColor = [26, 34, 63]) {
		let colorRange = Colors.interpolateColors(startColor, endColor, 3);
		let colorIndex = Math.abs(Colors.getHash(username) % 3);
		let color = `#${Colors.rgbToHex(colorRange[colorIndex])}`;
		let textColor = Colors.calculateTextColor(color);

		return {
			background: color,
			color: textColor,
		};
	}

	static interpolateColors(startColor: number[], endColor: number[], steps: number) {
		let stepFactor = 1 / (steps - 1);
		let interpolatedColors = [];
		for (let i = 0; i < steps; i++) {
			let color = [];
			for (let j = 0; j < 3; j++) {
				color.push(Math.round(startColor[j] + (endColor[j] - startColor[j]) * i * stepFactor));
			}
			interpolatedColors.push(color);
		}
		return interpolatedColors;
	}

	static rgbToHex(color: number[]) {
		let hex = color.map((c) => {
			let hexComponent = c.toString(16);
			return hexComponent.length == 1 ? '0' + hexComponent : hexComponent;
		});
		return hex.join('');
	}

	static calculateTextColor(backgroundColor: string) {
		// Converte a cor de fundo em um objeto RGB
		let rgb = Colors.hexToRgb(backgroundColor);
		// Calcula a luminosidade do fundo
		let luminance = Colors.calculateLuminance(rgb);
		// Retorna uma cor de texto preto ou branco, dependendo da luminosidade do fundo
		return luminance > 0.5 ? '#000' : '#fff';
	}

	static hexToRgb(hex: string) {
		let r = parseInt(hex.substring(1, 3), 16);
		let g = parseInt(hex.substring(3, 5), 16);
		let b = parseInt(hex.substring(5, 7), 16);
		return { r, g, b };
	}

	static hexToRgbString(hex: string) {
		var result = Colors.hexToRgb(hex);

		return `${result.r}, ${result.g}, ${result.b}`;
	}

	static calculateLuminance(rgb: { r: number; g: number; b: number }) {
		let a = [rgb.r, rgb.g, rgb.b].map(function (v) {
			v /= 255;
			return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
		});
		return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
	}

	static lightenDarkenColor(col: string, amt: number) {
		col = col.replace(/^#/, '');
		if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

		//@ts-ignore
		let [r, g, b] = col.match(/.{2}/g);
		[r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

		r = Math.max(Math.min(255, r), 0).toString(16);
		g = Math.max(Math.min(255, g), 0).toString(16);
		b = Math.max(Math.min(255, b), 0).toString(16);

		const rr = (r.length < 2 ? '0' : '') + r;
		const gg = (g.length < 2 ? '0' : '') + g;
		const bb = (b.length < 2 ? '0' : '') + b;

		return `#${rr}${gg}${bb}`;
	}
}

export default Colors;
